import React, {useState} from 'react'
import logo from '../../asset/images/logo.png'
import InstagramContact from '../../asset/images/contact-us/instagram.svg'
import LinkedInContact from '../../asset/images/contact-us/linkin.svg'
import XContact from '../../asset/images/contact-us/x.svg'
import {useNavigate} from 'react-router-dom'
import {ROUTE} from '../../router/routes'
import CustomDialog from '../CustomDialog'

const FooterV2 = () => {
  const navigator = useNavigate()
  const [type, setType] = useState<
    'ServicePolicy' | 'TermsOfUse' | 'PrivacyPolicy' | undefined
  >(undefined)
  return (
    <div className='py-11 px-4 flex flex-col gap-6 xl:px-[80px] xl:py-8'>
      <div className='flex flex-col gap-4 pb-6 border-b-[1px] xl:flex-row xl:items-center xl:justify-between'>
        <div className='flex gap-2 items-center'>
          <img
            className='w-11 h-11 xl:w-[56px] xl:h-[56px]'
            src={logo}
            alt='logo.png'
          />
          <div>
            <b className='font-bold xl:text-2xl'>GM4 Company</b>
            <p className='text-sm text-[#1854EE] xl:text-base'>Solution</p>
          </div>
        </div>
        <div className='flex gap-5 items-center'>
          <p className='xl:text-lg'>Follow Us On Social Media</p>
          <div className='flex gap-[14px]'>
            <img
              src={LinkedInContact}
              alt='linkedin'
              className='w-6 h-6 xl:w-16 xl:h-16'
            />
            <img
              src={InstagramContact}
              alt='instagram'
              className='w-6 h-6 xl:w-16 xl:h-16'
            />
            <img src={XContact} alt='x' className='w-6 h-6 xl:w-16 xl:h-16' />
          </div>
        </div>
      </div>
      <div className='flex flex-wrap gap-[30px]'>
        <div className='flex flex-col gap-3 w-[calc(50%-15px)] xl:w-[calc(25%-22.5px)]'>
          <p className='font-bold' onClick={() => navigator(ROUTE.HOME)}>
            gmapps.net
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            노코드 솔루션 <br />
            코딩없이 누구나 손쉽게 웹사이트와 애플리케이션을 만들 수 있어요
          </p>
        </div>
        <div className='flex flex-col gap-3 w-[calc(50%-15px)] xl:w-[calc(25%-22.5px)]'>
          <p className='font-bold'>회사정보</p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            회사명:지엠포컴퍼니 주식회사 | 대표 이승우
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            사업자등록번호: 491-87-02258
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            서울 강남구 선릉로 664 건설빌딩 205-1호
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            고객센터:0503-5692-5001
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            이메일:gm4-korea@greenapps.kr
          </p>
        </div>
        <div className='flex flex-col gap-3 w-[calc(50%-15px)] xl:w-[calc(25%-22.5px)]'>
          <p className='font-bold'>메뉴</p>
          <p
            onClick={() => navigator(ROUTE.HOME)}
            className='text-sm text-[#3F3F46] font-normal xl:text-base'
          >
            홈화면
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            회사소개
          </p>
          <p
            onClick={() => navigator(ROUTE.INTRODUCE)}
            className='text-sm text-[#3F3F46] font-normal xl:text-base'
          >
            템플릿 생성
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            구독
          </p>
          <p className='text-sm text-[#3F3F46] font-normal xl:text-base'>
            고객센터
          </p>
        </div>
        <div className='flex flex-col gap-3 w-[calc(50%-15px)] xl:w-[calc(25%-22.5px)]'>
          <p
            className='font-bold'
            onClick={() => navigator(ROUTE.SERVICE_POLICY)}
          >
            서비스 정책
          </p>
          <p
            className='text-sm text-[#3F3F46] font-normal xl:text-base'
            onClick={() => navigator(ROUTE.TERMS_OF_USE)}
          >
            이용약관
          </p>
          <p
            className='text-sm text-[#3F3F46] font-normal xl:text-base'
            onClick={() => navigator(ROUTE.PRIVACY_POLICY)}
          >
            개인정보보호정책
          </p>
        </div>
      </div>
      <div className='py-6 flex flex-col gap-6 border-t-[1px] xl:flex-row xl:justify-between'>
        <div className='flex justify-between xl:order-1 xl:gap-5'>
          <span className='text-sm text-[#3F3F46] font-normal'>
            Privacy Policy
          </span>
          <span className='text-sm text-[#3F3F46] font-normal'>
            Terms & Conditions
          </span>
          <span className='text-sm text-[#3F3F46] font-normal'>
            Cookie Policy
          </span>
        </div>
        <p className='text-center text-xs text-[#71717A] xl:order-[-1] xl:text-sm'>
          @2023 GM4 Solution. All Rights Reserved.
        </p>
        <p className='text-center text-xs text-[#666666] xl:text-sm'>
          Version 1.0
        </p>
      </div>
      {/* <CustomDialog
        open={!!type}
        onClose={() => setType(undefined)}
        type={type}
      /> */}
    </div>
  )
}

export default FooterV2
