import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import {makeStyles} from '@mui/styles'
import searchIcon from '../../asset/images/searchIcon.png'
import AddIcon from '@mui/icons-material/Add'
import {Checkbox} from '@mui/material'
import SiteItem from '../../components/siteItem'
import {useNavigate} from 'react-router-dom'
import {ROUTE} from '../../router/routes'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {selectListData, siteActions} from '../../features/site/siteSlice'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useEffect, useState} from 'react'
import {SiteType} from '../../types/site.type'
import {formatDate} from '../customercenter'
import {TYPE_SITE, TYPE_SORT} from '../../types/enum'
import {planActions} from '../../features/plan/planSlice'

const useStyles = makeStyles({
  '@media (min-width: 768px)': {
    site_list_mobile: {
      display: 'none',
    },
  },
  site_list_mobile: {
    padding: '12px',
    '&>p': {
      display: 'flex',
      justifyContent: 'space-between',
      '&>span': {},
    },
    '&>div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1rem',
      '&>span': {
        display: 'flex',
        '&>span': {
          width: '28px',
          height: '28px',
          display: 'flex',
          background: '#2B83FE',
          borderRadius: '100px',
          justifyContent: 'center',
          fontSize: '12px',
          color: 'white',
          alignItems: 'center',
          marginLeft: '8px',
        },
      },
    },
    '&>div:nth-of-type(3)': {
      marginTop: '1rem',
      '&>div:nth-of-type(1)': {
        background: '#F1F1F1',
        borderRadius: '4px',
        color: '#70777F',
        fontSize: '14px',
      },
    },
  },
})

interface SiteListMobileProps {
  typeQuery?: TYPE_SITE
  setTypeQuery?: (e?: TYPE_SITE) => void
}

const SiteListMobile = ({typeQuery, setTypeQuery}: SiteListMobileProps) => {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const listDataWebsite = useAppSelector(selectListData)
  const [page, setPage] = useState<number>(1)
  const [rows, setRows] = useState<any>([
    {
      id: '',
      address: '',
      endDate: '',
      expirationDate: '',
      startDate: '',
      director: '',
      webInfo: '',
      androidInfo: '',
      iosInfo: '',
    },
  ])
  const perPage = 15
  const handleChangeTypeSite = (event: SelectChangeEvent) => {
    setTypeQuery && setTypeQuery(event.target.value as any)
    setPage(1)
  }
  useEffect(() => {
    let params: any = {page, perPage, _sort: TYPE_SORT.CREATED_AT_DESC}
    if (typeQuery) {
      params = {...params, typeQuery}
    }

    dispatch(
      siteActions.getList({
        params: {...params},
      })
    )
    dispatch(planActions.getList({params: undefined}))
  }, [dispatch, page, typeQuery])

  useEffect(() => {
    if (!listDataWebsite) return
    else {
      const data: any = []
      listDataWebsite?.forEach((item: SiteType, index) => {
        const transformedData = {
          id: item._id,
          address: `${item.name || '사이트 이름 미정'} \n${
            item.webInfo?.domainName || '도메인 미정'
          }`,
          endDate: `${Math.floor(item.remainingDays)}일 남음`,
          expirationDate: formatDate(item.expirationDate || ''),
          startDate: formatDate(item.createdAt || ''),
          director: item.adminEmail,
          webInfo: item.webInfo || '',
          androidInfo: item.androidInfo || '',
          iosInfo: item.iosInfo || '',
        }
        data.push(transformedData)
      })
      setRows(data)
    }
  }, [listDataWebsite])
  return (
    <div className={classes.site_list_mobile}>
      <p>
        <span>분류</span>{' '}
        <Select
          // labelId='demo-simple-select-label'
          // id='demo-simple-select'
          key={typeQuery}
          value={typeQuery}
          onChange={handleChangeTypeSite}
          displayEmpty
          sx={{
            height: '32px',
            borderColor: '#D0D5DD',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            background: '#F1F1F1',
            borderRadius: '100px',
            marginBottom: '12px',
          }}
          inputProps={{'aria-label': 'Without label'}}
        >
          <MenuItem value={undefined}>전체</MenuItem>
          <MenuItem value={TYPE_SITE.IS_IN_USE}>운영상태</MenuItem>
          <MenuItem value={TYPE_SITE.EXPIRED}>만료상태</MenuItem>
        </Select>{' '}
      </p>
      <TextField
        id='outlined-start-adornment'
        placeholder='Search bar...'
        size='small'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment
              style={{marginLeft: '2px', padding: 0}}
              position='start'
            >
              <img src={searchIcon} style={{height: '24px', width: '24px'}} />
            </InputAdornment>
          ),
          style: {
            padding: '0 0 0 16px',
            margin: 0,
            borderRadius: '8px',
            borderColor: '#D0D5DD',
            fontSize: '16px',
            fontWeight: 400,
          },
        }}
      />
      <div>
        <span>
          생성한 사이트{' '}
          {listDataWebsite?.length && <span>{listDataWebsite?.length}</span>}
        </span>
        <Button
          variant='contained'
          style={{background: '#2B83FE', borderRadius: '0'}}
          onClick={() => {
            navigate(ROUTE.SITECREATION)
          }}
        >
          <AddIcon /> 사이트 생성
        </Button>
      </div>
      <div>
        <div>
          <Checkbox defaultChecked={false} /> 모두 선택
        </div>
        {rows.length > 0 && (
          <InfiniteScroll
            dataLength={listDataWebsite.length || 0}
            next={() => setPage(page + 1)}
            hasMore={true}
            loader={<></>}
          >
            {rows.map((item: any) => (
              <SiteItem key={item.id} data={item} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default SiteListMobile
