import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import {makeStyles} from '@mui/styles'
import * as React from 'react'
import iconSidebar1 from '../../asset/images/iconSidebar1.png'
import iconSidebar1Active from '../../asset/images/iconSidebar1Active.png'
import iconSidebar2 from '../../asset/images/iconSidebar2.png'
import iconSidebar2Active from '../../asset/images/iconSidebar2Active.png'
import iconSidebar3 from '../../asset/images/iconSidebar3.png'
import iconSidebar3Active from '../../asset/images/iconSidebar3Active.png'
import iconSidebar4 from '../../asset/images/iconSidebar4.png'
import iconSidebar4Active from '../../asset/images/iconSidebar4Active.png'
import {ROUTE} from '../../router/routes'
import {useLocation, useNavigate} from 'react-router-dom'
import {Modal} from '@mui/material'
import cancelCircleIcon from '../../asset/images/cancelCircle.png'
import logo from '../../asset/images/logo.png'

const useStyles = makeStyles({
  sidebar_mobile_container: {
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  sidebar_mobile: {
    position: 'absolute' as 'absolute',
    // top: '40%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    margin: '24px 16px',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      '&>img': {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
      },
      '&>div': {
        display: 'flex',
        gap: '6px',
        paddingRight: '4px',
        cursor: 'pointer',
        '&>img': {
          width: '48px',
          height: '48px',
        },
        '&>div': {
          fontWeight: 700,
          fontSize: '16px',
          color: '#fff',
          '&>p': {
            color: 'blue',
            fontWeight: 500,
            fontSize: '14px',
            padding: 0,
            margin: 0,
          },
        },
      },
    },
    '&>ul': {
      display: 'flex',
      justifyContent: 'center',
      // alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: '32px',
      '&>li': {
        listStyle: 'none',
        padding: '8px 0px',
        fontSize: '20px',
        fontWeight: 700,
        cursor: 'pointer',
        color: '#fff',
        '&:hover': {
          color: '#00FFC2',
        },
      },
      '&>div': {
        padding: '8px 0px',
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        flexDirection: 'column',
        gap: '4px',
        '&>p': {
          margin: 0,
          padding: 0,
          fontSize: '20px',
          fontWeight: 700,
          cursor: 'pointer',
          color: '#fff',
          '&:hover': {
            color: '#00FFC2',
          },
        },
        '&>div': {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 12px',
          borderRadius: '8px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 255, 194, 0.32)',
          },
          '&>img': {
            width: '24px',
            height: '24px',
            filter: 'invert(1)',
          },
          '&>p': {
            color: '#fff',
            fontWeight: 500,
            fontSize: '14px',
            padding: 0,
            margin: 0,
          },
        },
      },
    },
  },
  list: {
    position: 'fixed',
    left: 0,
    zIndex: 1001,
    height: '100%',
    width: '85%',
    display: 'flex',
    boxSizing: 'border-box',
    '&>div:nth-child(1)': {
      backgroundColor: '#fff',
      boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.12)',
      padding: '24px 24px 0 24px',
      flex: 1,
      overflow: 'auto',
      '&>img': {
        height: '24px',
        width: '24px',
      },
      '&>div': {
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    '&>div:nth-child(2)': {
      padding: '49px 0 0 24px',
    },
  },
})

const dataDeposits = [
  {
    name: '사이트 목록 및 만료목록',
    icon: iconSidebar3,
    iconActive: iconSidebar3Active,
    path: ROUTE.SITELISTANDEXPIREDLIST,
  },
  {
    name: '예치금충전 및 내역',
    icon: iconSidebar1,
    iconActive: iconSidebar1Active,
    path: ROUTE.DEPOSITANDHISTORY,
  },
  {
    name: '사이트생성',
    icon: iconSidebar2,
    iconActive: iconSidebar2Active,
    path: ROUTE.SITECREATION,
  },
  // {
  //   name: '고객센터',
  //   icon: iconSidebar4,
  //   iconActive: iconSidebar4Active,
  //   path: ROUTE.CUSTOMERCENTER,
  // },
]
const dataHeaders = [
  {
    name: 'About',
    icon: iconSidebar1,
    iconActive: iconSidebar1Active,
    path: ROUTE.ABOUT,
  },
  {
    name: 'Solution',
    icon: iconSidebar1,
    iconActive: iconSidebar1Active,
    path: ROUTE.DEPOSITANDHISTORY,
  },
  {
    name: 'Price',
    icon: iconSidebar1,
    iconActive: iconSidebar1Active,
    path: ROUTE.PRICE,
  },
  {
    name: 'Contact',
    icon: iconSidebar1,
    iconActive: iconSidebar1Active,
    path: ROUTE.CUSTOMERCENTER,
  },
]

interface IProps {
  isOpen: boolean
  dataHeader?: boolean
}

export default function SidebarMobile(props: IProps) {
  let windowWidth = window.innerWidth
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()
  const {isOpen, dataHeader} = props
  console.log('dataHeader isOpen check', isOpen)

  const [showSidebarHeader, setShowSidebarHeader] = React.useState(false)

  const [isShowSolutions, setIsShowSolutions] = React.useState(false)

  const [checkFirstTime, setCheckFirstTime] = React.useState(false)

  const handleCloseSideBar = () => setShowSidebarHeader(false)

  const handleClickAbout = () => {
    navigate(ROUTE.HOME)
    setShowSidebarHeader(false)
  }
  const handleClickIntroduce = () => {
    navigate(ROUTE.INTRODUCE)
    setShowSidebarHeader(false)
  }

  const handleClickSolutions = () => {
    setIsShowSolutions(!isShowSolutions)
  }
  const handleClickItemSolutions = (path: string) => {
    if (!!localStorage.getItem('accessToken')) {
      setShowSidebarHeader(false)
      navigate(path)
    } else {
      alert('이 기능을 사용하려면 로그인해야 합니다.')
    }
  }
  const handleClickPrice = () => {
    setShowSidebarHeader(false)
    // navigate(ROUTE.PRICE)
    scrollToElement()
  }
  const handleClickContact = () => {
    if (!!localStorage.getItem('accessToken')) {
      setShowSidebarHeader(false)
      navigate(ROUTE.CUSTOMERCENTER)
    } else {
      alert('이 기능을 사용하려면 로그인해야 합니다.')
    }
  }

  const checkSolutions = () => {
    let check = false
    dataDeposits.map((item, index) => {
      if (location.pathname.includes(item.path)) {
        check = true
      }
    })
    console.log('checkSolutions', check)

    return check
  }

  React.useLayoutEffect(() => {
    setCheckFirstTime(true)
    if (checkFirstTime) {
      if (windowWidth > 768) {
        setShowSidebarHeader(false)
      } else {
        setShowSidebarHeader(true)
      }
    }
  }, [isOpen])

  const scrollToElement = () => {
    const element = document.getElementById('Section-RegisterPlan')
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset
      const offsetPosition = elementPosition - 84

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={classes.sidebar_mobile_container}>
      <Modal
        open={showSidebarHeader}
        onClose={handleCloseSideBar}
        disableAutoFocus
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)', // Set your desired color here
            // Add other styles as needed
          },
        }}
      >
        <div className={classes.sidebar_mobile}>
          <div>
            <img src={cancelCircleIcon} alt='' onClick={handleCloseSideBar} />
            <div
              onClick={() => {
                navigate(ROUTE.HOME)
              }}
            >
              <img src={logo} alt='logo' />
              <div>
                GM4 Company
                <p>Solution</p>
              </div>
            </div>
          </div>
          <ul>
            <li
              style={location.pathname === ROUTE.HOME ? {color: '#00FFC2'} : {}}
              onClick={handleClickAbout}
            >
              HOME
            </li>
            <li
              style={
                location.pathname === ROUTE.INTRODUCE ? {color: '#00FFC2'} : {}
              }
              onClick={handleClickIntroduce}
            >
              회사소개
            </li>
            <div>
              <p
                style={checkSolutions() ? {color: '#00FFC2'} : {}}
                onClick={handleClickSolutions}
              >
                템플릿 생성
              </p>
              {isShowSolutions &&
                dataDeposits.map((item, index) => (
                  <div
                    style={
                      location.pathname.includes(item.path)
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 12px',
                            gap: '8px',
                            fontWeight: 500,
                            color: '#fff',
                            backgroundColor: 'rgba(0, 255, 194, 0.32)',
                            borderRadius: '8px',
                            cursor: 'pointer',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 10px',
                            gap: '8px',
                            fontWeight: 500,
                            color: '#70777F',
                            cursor: 'pointer',
                          }
                    }
                    onClick={() => handleClickItemSolutions(item.path)}
                  >
                    <img style={{filter: 'invert(1)'}} src={item.iconActive} />
                    <p>{item.name}</p>
                  </div>
                ))}
            </div>
            <li
              style={
                location.pathname === ROUTE.PRICE ? {color: '#00FFC2'} : {}
              }
              onClick={handleClickPrice}
            >
              구독
            </li>
            <li
              style={
                location.pathname === ROUTE.CUSTOMERCENTER
                  ? {color: '#00FFC2'}
                  : {}
              }
              onClick={handleClickContact}
            >
              고객센터
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  )
}
