import {Modal, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {signInWithPopup} from 'firebase/auth'
import {useEffect, useLayoutEffect, useState} from 'react'
import OtpInput from 'react-otp-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useLocation, useNavigate} from 'react-router-dom'
import axiosClient, {setTokens} from '../../apis/axiosClient'
import {
  CHECK_MOBILE_PHONE,
  LOGIN,
  UPDATE_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER,
} from '../../apis/urlConfig'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import hamburgerMenu from '../../asset/images/HamburgerMenu.png'
import closeIcon from '../../asset/images/cancel.png'
import closeWhiteIcon from '../../asset/images/cancelWhite.png'
import iconBack from '../../asset/images/iconBack.png'
import warningIcon from '../../asset/images/iconWarning.png'
import logo from '../../asset/images/logo.png'
import polygon from '../../asset/images/polygon.png'
import {
  selectLoginData,
  selectUserData,
  userActions,
} from '../../features/user/userSlice'
import {ROUTE} from '../../router/routes'
import {auth, provider} from '../../services/firebase'
import {setTokensLocalStorage} from '../../utils'
import {loadingActions} from '../loading/loadingSlice'
import {snackBarActions} from '../snackbar/snackbarSlice'

const useStyles = makeStyles({
  container_header: {
    position: 'fixed',
    zIndex: 1000,
    display: 'flex',
    maxWidth: '1240px',
    width: '1240px',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    gap: '44px',
    top: '16px',
    left: 0,
    right: 0,
    padding: '20px',
    margin: '0 auto',
    alignItems: 'center',
    borderRadius: '100px',
    boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.12)',
    '&>div:nth-child(1)': {
      '&>img:nth-of-type(1)': {
        display: 'none',
      },
      '&>div:nth-of-type(1)': {
        display: 'flex',
        height: '56px',
        gap: '6px',
        paddingRight: '4px',
        alignItems: 'center',
        '&>img': {
          width: '56px',
          height: '56px',
        },
        '&>div': {
          fontWeight: 700,
          fontSize: '24px',
          '&>p': {
            color: 'blue',
            fontWeight: 500,
            fontSize: '16px',
            padding: 0,
            margin: 0,
          },
        },
      },
    },
    '&>ul': {
      flexGrow: '1',
      listStyle: 'none',
      display: 'flex',
      gap: '64px',
      padding: 0,
      margin: 0,
      fontSize: '18px',
      fontWeight: 500,
      '&>li': {
        cursor: 'pointer',
        '&:hover': {
          color: 'blue',
        },
      },
    },
    '&>:nth-child(3)': {
      '&>div': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: '#fff',
        border: '1px solid #D0D5DD',
        borderRadius: '100px',
        fontSize: '18px',
        fontWeight: 500,
        padding: '4px 12px 4px 6px',
        '&>img:nth-child(1)': {
          width: '40px',
          height: '40px',
          borderRadius: '50%',
        },
        '&>img:nth-child(2)': {
          width: '32px',
          height: '32px',
        },
      },
      '&>button': {
        backgroundColor: '#fff',
        border: '1px solid #D0D5DD',
        borderRadius: '100px',
        alignItems: 'center',
        fontSize: '18px',
        fontWeight: 500,
        padding: '4px 12px',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#3B71FE',
        },
      },
    },
  },
  modal: {
    position: 'absolute',
    right: '104px',
    top: '96px',
    width: '308px',
    padding: '16px 20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.25)',
    '&>div:nth-of-type(1)': {
      '&>img': {
        position: 'absolute',
        right: '16px ',
        top: '-12px',
        width: '12px',
        height: '12px',
      },
    },
    '&>div:nth-of-type(2)': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      '&>img:nth-of-type(1)': {
        width: '54px',
        height: '54px',
        borderRadius: '50%',
      },
      '&>div': {
        '&>p': {
          padding: 0,
          margin: 0,
        },
        '&>p:nth-of-type(1)': {
          fontWeight: 700,
          fontSize: '16px',
        },
        '&>p:nth-of-type(2)': {
          fontWeight: 400,
          fontSize: '14px',
        },
      },
    },
    '&>p:nth-of-type(1)': {
      padding: 0,
      margin: '24px 0 12px 0',
      fontWeight: 500,
      fontSize: '16px',
      cursor: 'pointer',
    },
    '&>p:nth-of-type(2)': {
      padding: 0,
      margin: 0,
      fontWeight: 400,
      fontSize: '16px',
      color: '#272B30',
      cursor: 'pointer',
    },
  },
  modalPhone: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    border: 'none',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      padding: '16px 32px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #EDEDED',
      textAlign: 'center',
      '&>div': {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        '&>img': {
          cursor: 'pointer',
          height: '24px',
          width: '24px',
        },
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '20px',
          fontWeight: 500,
          textAlign: 'center',
        },
      },
      '&>img': {
        cursor: 'pointer',
        height: '24px',
        width: '24px',
      },
    },
    '&>div:nth-of-type(2)': {
      padding: '24px 32px 32px 32px',
      alignItems: 'center',
      // borderBottom: '1px solid #EDEDED',
      width: 'calc(446px)',
      minHeight: 'calc(120px)',
      '&>p': {
        padding: 0,
        margin: '0 0 8px 0',
        fontSize: '16px',
        fontWeight: 500,
        color: '#272B30',
      },
      '&>p:nth-of-type(3)': {
        margin: '8px 0 0 0',
        '&>span': {
          color: '#0162F2',
          cursor: 'pointer',
        },
      },
    },
    '&>button': {
      width: 'calc(100% - 64px)',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      borderRadius: '8px',
      backgroundColor: '#2B83FE',
      padding: '10px 24px',
      textAlign: 'center',
      cursor: 'pointer',
      margin: '0px 32px 24px 32px',
      '&>p': {
        padding: 0,
        margin: 0,
        fontSize: '16px',
        fontWeight: 500,
        color: '#fff',
      },
    },
  },

  captcha: {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
  },

  // Mobile responsive
  '@media (max-width: 768px)': {
    container_header: {
      padding: '16px',
      border: 'none',
      boxShadow: 'none',
      justifyContent: 'space-between',
      borderRadius: 0,
      margin: 0,
      width: '100%',
      top: 0,
      '&>div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        '&>img:nth-of-type(1)': {
          display: 'block',
          width: '24px',
          height: '24px',
        },
        '&>div:nth-of-type(1)': {
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          '&>img': {
            width: '44px',
            height: '44px',
          },
          '&>div': {
            fontWeight: 700,
            fontSize: '16px',
            '&>p': {
              color: 'blue',
              fontWeight: 500,
              fontSize: '12px',
              padding: 0,
              margin: 0,
            },
          },
        },
      },
      '&>ul': {
        display: 'none',
      },
      '&>:nth-child(3)': {
        '&>div': {
          border: 'none',
          padding: '0',
          '&>img:nth-child(1)': {
            width: '36px',
            height: '36px',
            borderRadius: '50%',
          },
          '&>img:nth-child(2)': {
            display: 'none',
          },
        },
        '&>button': {
          backgroundColor: '#fff',
          border: '1px solid #D0D5DD',
          borderRadius: '100px',
          alignItems: 'center',
          fontSize: '18px',
          fontWeight: 500,
          padding: '4px 12px',
        },
      },
    },
    modal: {
      position: 'absolute',
      right: '18px',
      top: '96px',
      width: '300px',
    },
    modalPhone: {
      position: 'absolute',
      flexWrap: 'wrap',
      width: '300px',
      '&>div:nth-of-type(1)': {
        padding: '8px',
        // borderBottom: '1px solid #EDEDED',
        width: 'calc(300px - 16px)',
        minHeight: 'calc(120px)',
        '&>p': {
          padding: 0,
          margin: '0 0 8px 0',
          fontSize: '16px',
          fontWeight: 500,
          color: '#272B30',
        },
      },
    },
  },
})
interface IProps {
  handleButtonShow: (check: boolean) => void
}
const Header = (props: IProps) => {
  const {handleButtonShow} = props

  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(selectUserData)
  const loginData = useAppSelector(selectLoginData)

  const [isShowSideBar, setIsShowSideBar] = useState(true)
  const [tokenFirebase, setTokenFirebase] = useState('')
  const [statusLogin, setStatusLogin] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalPhone, setOpenModalPhone] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [showOtp, setShowOtp] = useState(false)
  const [showProfile, setShowProfile] = useState(false)

  const [dataPhoneExist, setDataPhoneExist] = useState<{
    phoneNumber: ''
    email: ''
  }>()
  const [otpVerifyError, setOtpVerifyError] = useState('')
  console.log('dataPhoneExist: ', dataPhoneExist)
  const handleClick = () => {
    setIsShowSideBar(!isShowSideBar)
    handleButtonShow(isShowSideBar)
  }

  const handleClickMenuUser = () => {
    setOpenModal(!openModal)
  }

  const handleCloseModal = () => setOpenModal(false)
  const handleCloseModalPhone = () => {
    localStorage.clear()
    dispatch(userActions.deleteUser({params: undefined}))
    setIsShowSideBar(true)
    setTokenFirebase('')
    setStatusLogin(false)
    setOpenModal(false)
    setOpenModalPhone(false)
    setScroll(false)
    setPhone('')
    setOtp('')
    setOtpVerifyError('')
    setShowOtp(false)
    setShowProfile(false)
    setDataPhoneExist(undefined)
    navigate(ROUTE.HOME)
  }

  const handleCheckExistPhone = async () => {
    let checkPhone = false
    const formatPhone = '+' + phone
    console.log('formatPhone: ', formatPhone)

    await axiosClient
      .get(CHECK_MOBILE_PHONE, {params: {mobileNumber: formatPhone}})
      .then((res: any) => {
        if (res.statusCode === 200) {
          console.log('checkPhone response::', res)
          checkPhone = true
          setDataPhoneExist(undefined)
        }
      })
      .catch((error: any) => {
        console.log('Abc:', error)
        setDataPhoneExist(error.response.data.data)
      })
    return checkPhone
  }

  const onSignUp = async () => {
    const checkPhone = await handleCheckExistPhone()
    console.log('checkPhone: ', checkPhone)
    if (checkPhone) {
      const formatPhone = '+' + phone

      dispatch(loadingActions.openLoading())
      const data = {
        mobileNumber: formatPhone,
      }
      console.log('data Put UPDATE_PHONE_NUMBER: ', data)
      axiosClient
        .put(UPDATE_PHONE_NUMBER, data)
        .then((res: any) => {
          if (res.statusCode === 200) {
            console.log('message Put UPDATE_PHONE_NUMBER success: ', res)
            dispatch(loadingActions.loadingSuccess())
            setShowOtp(true)
          } else {
            console.log('message Put UPDATE_PHONE_NUMBER failed: ', res.message)
            dispatch(loadingActions.loadingSuccess())
            // localStorage.clear()
            dispatch(
              snackBarActions.setStateSnackBar({
                content: '실패',
                type: 'error',
              })
            )
          }
        })
        .catch((error: any) => {
          console.log('Abc:', error)
          dispatch(loadingActions.loadingSuccess())
          // localStorage.clear()
          dispatch(
            snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            })
          )
        })
    }
  }

  const onOTPVerify = () => {
    const formatPhone = '+' + phone
    console.log('IFFFFF checkPhone: ', formatPhone)

    dispatch(loadingActions.openLoading())
    const data = {
      mobileNumber: formatPhone,
      otp: otp,
    }

    console.log('data Put VERIFY_PHONE_NUMBER: ', data)
    axiosClient
      .put(VERIFY_PHONE_NUMBER, data)
      .then(async (res: any) => {
        console.log('res onOTPVerify: ', res)
        dispatch(loadingActions.loadingSuccess())
        setPhone('')
        setOtp('')
        setOtpVerifyError('')
        setShowOtp(false)
        setDataPhoneExist(undefined)
        setOpenModalPhone(false)
        setShowProfile(true)
        dispatch(
          snackBarActions.setStateSnackBar({
            content: '성공',
            type: 'success',
          })
        )
      })
      .catch((error: any) => {
        dispatch(loadingActions.loadingSuccess())
        console.log('error onOTPVerify: ', error.response.data.message)
        setOtpVerifyError(error?.response?.data?.message)
      })
  }

  const handleReSendOTP = () => {
    setOtp('')
    setOtpVerifyError('')
    onSignUp()
  }

  const handleLogin = () => {
    if (!localStorage.getItem('accessToken')) {
      signInWithPopup(auth, provider)
        .then((data: any) => {
          setTokenFirebase(data.user.accessToken)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  const handleLogout = () => {
    if (window.confirm('로그아웃하시겠습니까?')) {
      localStorage.clear()
      dispatch(userActions.deleteUser({params: undefined}))

      setIsShowSideBar(true)
      setTokenFirebase('')
      setStatusLogin(false)
      setOpenModal(false)
      setOpenModalPhone(false)
      setScroll(false)
      setPhone('')
      setOtp('')
      setOtpVerifyError('')
      setShowOtp(false)
      setShowProfile(false)
      setDataPhoneExist(undefined)
      navigate(ROUTE.HOME)
    }
  }
  const handleClickSolution = () => {
    if (!!localStorage.getItem('accessToken')) {
      navigate(ROUTE.SITELISTANDEXPIREDLIST)
    } else {
      alert('이 기능을 사용하려면 로그인해야 합니다.')
    }
  }
  const handleClickPrice = () => {
    // navigate(ROUTE.PRICE)
    scrollToElement()
  }
  const handleClickContact = () => {
    if (!!localStorage.getItem('accessToken')) {
      navigate(ROUTE.CUSTOMERCENTER)
    } else {
      alert('이 기능을 사용하려면 로그인해야 합니다.')
    }
  }
  useLayoutEffect(() => {
    if (tokenFirebase) {
      const data = {
        firebaseToken: tokenFirebase,
      }
      axiosClient
        .post(LOGIN, data)
        .then((res: any) => {
          if (res.statusCode === 200) {
            setStatusLogin(!statusLogin)
            setTokensLocalStorage(res.data)
            dispatch(loadingActions.loadingSuccess())
          } else {
            dispatch(loadingActions.loadingSuccess())
            dispatch(
              snackBarActions.setStateSnackBar({
                content: '실패',
                type: 'error',
              })
            )
          }
        })
        .catch((error: any) => {
          console.log(error)
          dispatch(loadingActions.loadingSuccess())
          dispatch(
            snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            })
          )
        })
    }
  }, [tokenFirebase])

  useLayoutEffect(() => {
    if (
      !!localStorage.getItem('accessToken') &&
      Object.entries(userProfile).length === 0
    ) {
      setTokens()
      dispatch(userActions.getUser({params: undefined}))
    }
    if (!localStorage.getItem('accessToken')) {
      dispatch(userActions.deleteUser({params: undefined}))
    }
  }, [statusLogin])

  useEffect(() => {
    if (
      Object.entries(userProfile).length !== 0 &&
      !userProfile.hasOwnProperty('mobileNumber') &&
      statusLogin
    ) {
      setOpenModalPhone(true)
    } else if (
      Object.entries(userProfile).length !== 0 &&
      userProfile.hasOwnProperty('mobileNumber') &&
      statusLogin
    ) {
      setShowProfile(true)
    }
  }, [userProfile])

  useEffect(() => {
    if (Object.entries(userProfile).length !== 0) {
      setShowProfile(true)
    } else if (
      !!localStorage.getItem('accessToken') &&
      Object.entries(userProfile).length == 0
    ) {
      dispatch(userActions.getUser({params: undefined}))
      setShowProfile(true)
    }
  }, [])

  useEffect(() => {
    if (loginData) {
      handleLogin()
    }
  }, [loginData])

  const scrollToElement = () => {
    const element = document.getElementById('Section-RegisterPlan')
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset
      const offsetPosition = elementPosition - 84

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div
      className={classes.container_header}
      style={{background: scroll ? 'white' : 'white'}}
    >
      <div>
        <img src={hamburgerMenu} alt='' onClick={handleClick} />
        <div onClick={() => navigate(ROUTE.HOME)}>
          <img src={logo} alt='logo' />
          <Typography
            sx={{display: {md: 'block', xs: 'none'}, fontSize: '24px'}}
          >
            GM4 Company
            <Typography component='p' sx={{fontSize: '16px', color: '#1854EE'}}>
              Solution
            </Typography>
          </Typography>
        </div>
      </div>
      <ul>
        <li
          style={location.pathname === ROUTE.HOME ? {color: 'blue'} : {}}
          onClick={() => navigate(ROUTE.HOME)}
        >
          HOME
        </li>
        <li
          style={location.pathname === ROUTE.INTRODUCE ? {color: 'blue'} : {}}
          onClick={() => navigate(ROUTE.INTRODUCE)}
        >
          회사소개
        </li>
        <li
          style={
            location.pathname === ROUTE.SITELISTANDEXPIREDLIST
              ? {color: 'blue'}
              : {}
          }
          onClick={handleClickSolution}
        >
          템플릿 생성
        </li>
        <li
          style={location.pathname === ROUTE.PRICE ? {color: 'blue'} : {}}
          onClick={handleClickPrice}
        >
          구독
        </li>
        <li onClick={handleClickContact}>고객센터</li>
      </ul>
      <div>
        {!!localStorage.getItem('accessToken') &&
        Object.entries(userProfile).length !== 0 &&
        showProfile ? (
          <div onClick={handleClickMenuUser}>
            <img src={userProfile?.photo} alt='' />
            <img src={hamburgerMenu} alt='' />
          </div>
        ) : (
          <button onClick={handleLogin}>로그인</button>
          // <button onClick={() => setOpenModalPhone(true)}>
          //   login phone
          // </button>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        disableAutoFocus
        sx={{
          '.MuiModal-backdrop': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className={classes.modal}>
          <div>
            <img src={polygon} alt='' />
          </div>
          <div>
            <img src={userProfile?.photo} alt='' />
            <div>
              <p>
                {userProfile?.firstName} {userProfile?.lastName}
              </p>
              <p>{userProfile?.email}</p>
            </div>
          </div>
          <p>파트너 관리</p>
          <p onClick={handleLogout}>로그아웃</p>
        </div>
      </Modal>

      <Modal
        open={openModalPhone}
        onClose={handleCloseModalPhone}
        disableAutoFocus
      >
        <div className={classes.modalPhone}>
          <div>
            <div>
              {showOtp && (
                <img
                  src={iconBack}
                  alt='back'
                  onClick={() => setShowOtp(false)}
                />
              )}
              <p>회원가입 전화번호 인증</p>
            </div>
            <img src={closeIcon} alt='close' onClick={handleCloseModalPhone} />
          </div>
          {!showOtp ? (
            <div>
              <p>연락처</p>
              <PhoneInput
                country={'kr'}
                value={phone}
                onChange={setPhone}
                inputStyle={{
                  width: '100%',
                  height: '100%',
                  padding: '10px 16px 10px 48px',
                  fontSize: '16px',
                  fontWeight: '500',
                  borderRadius: '8px',
                }}
                containerStyle={{height: '44px'}}
              />
              {dataPhoneExist != undefined && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '12px 0',
                    padding: '12px',
                    background: 'linear-gradient(95deg,#F6743E, #D42525)',
                    borderRadius: '12px',
                  }}
                >
                  <div style={{display: 'flex', gap: '12px'}}>
                    <img
                      src={warningIcon}
                      alt='close'
                      onClick={handleCloseModalPhone}
                      style={{width: '24px', height: '24px'}}
                    />
                    <div>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: '14px',
                          color: 'white',
                          marginBottom: '4px',
                        }}
                      >
                        이미 가입되어 있는 번호입니다.
                      </p>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: '14px',
                          color: 'white',
                        }}
                      >
                        <span style={{fontWeight: 'bold'}}>
                          {dataPhoneExist?.email}
                        </span>
                        가 위에 번호로 가입 되어 있는 아이디입니다.
                      </p>
                    </div>
                  </div>
                  <img
                    src={closeWhiteIcon}
                    alt='close'
                    onClick={() => {
                      setDataPhoneExist(undefined)
                    }}
                    style={{width: '14px', height: '14px'}}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>연락처</p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                // inputType="number"
                inputStyle={{
                  width: '100%',
                  height: '100%',
                  margin: '10px',
                  fontSize: '16px',
                  fontWeight: '500',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                }}
                containerStyle={{height: '44px'}}
              />
              <p style={{color: 'red', fontSize: '14px', marginTop: '4px'}}>
                {otpVerifyError}
              </p>
              <p>
                인증번호를 받지 못하셨습니까?{' '}
                <span onClick={handleReSendOTP}>다시 보내기</span>
              </p>
            </div>
          )}
          <button
            onClick={!showOtp ? onSignUp : onOTPVerify}
            disabled={phone.length < 10}
            style={phone.length < 10 ? {background: '#ccc'} : {}}
          >
            <p>{!showOtp ? '다음' : '인증'}</p>
          </button>
        </div>
      </Modal>
      <div className={classes.captcha} id='recaptcha-container'></div>
    </div>
  )
}

export default Header
