import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {makeStyles} from '@mui/styles'
import moment from 'moment'
import React, {useState} from 'react'
import appleIcon from '../../asset/images/AppleLogo.png'
import chplay from '../../asset/images/CHPlayIcon.png'
import {STATUS_SITE_UPDATE} from '../../types/enum'
import {ROUTE} from '../../router/routes'
import {useNavigate} from 'react-router-dom'
import {Modal, Select, SelectChangeEvent} from '@mui/material'
import {PlanType} from '../../types/plan.type'
import {planSliceFunc} from '../../features/plan/planSlice'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import axiosClient from '../../apis/axiosClient'
import {SITE} from '../../apis/urlConfig'
import {selectUserData, userActions} from '../../features/user/userSlice'
import {SiteType} from '../../types/site.type'
import {siteActions} from '../../features/site/siteSlice'
import {snackBarActions} from '../snackbar/snackbarSlice'
import {numberWithCommas} from '../../utils'
import Social from '../../asset/images/Social.png'
import wallet02 from '../../asset/images/Wallet 02.png'
import closeIcon from '../../asset/images/cancel.png'

const useStyles = makeStyles({
  site_item_container: {
    marginTop: '1rem',
    background: '#FFFFFF',
    border: '0.5px solid #D0D5DD',
    borderRadius: '8px',
    padding: '10px 0',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'space-between',
      '&>div:nth-of-type(1)': {
        display: 'flex',
        '&>div:nth-of-type(1)': {
          '&>p': {
            margin: 0,
            fontSize: '14px',
          },
          '&>p:nth-of-type(2)': {
            fontWeight: 400,
            fontSize: '10px',
            color: '#343941',
          },
        },
      },
    },
    '&>div:nth-of-type(3)': {
      padding: '0 12px',
      '&>p': {
        fontWeight: 400,
        fontSize: '14px',
        '&>span:nth-of-type(1)': {
          fontSize: '14px',
          marginRight: '2rem',
          fontWeight: 600,
        },
        '&>span:nth-of-type(2)': {
          color: '#FD3535',
        },
      },
      '&>p:nth-of-type(1)': {
        marginTop: '5px',
      },
      '&>p:nth-of-type(3)': {
        marginBottom: '0',
      },
    },
  },
  style_menu_item: {
    fontSize: '14px',
  },
  modalRenew: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    border: 'none',
    width: '396px',
    // padding: '4px',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      padding: '16px 24px 0px 32px',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      '&>p': {
        padding: 0,
        margin: 0,
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
      },
      '&>img': {cursor: 'pointer', height: '24px', width: '24px'},
    },
    '&>div:nth-of-type(2)': {
      padding: '0px 24px 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      justifyContent: 'center',
      alignItems: 'center',
      '&>img': {height: '160px', width: '160px'},
      '&>div': {
        '&>p': {
          padding: 0,
          margin: '0 0 8px 0',
          fontSize: '18px',
          fontWeight: 700,
          color: '#111315',
          textAlign: 'center',
        },
        '&>p:nth-of-type(3)': {
          padding: 0,
          margin: 0,
          fontSize: '16px',
          fontWeight: 400,
          color: '#272B30',
          textAlign: 'center',
        },
      },
    },
    '&>div:nth-of-type(3)': {
      display: 'flex',
      padding: ' 0 24px 24px',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      gap: '24px',
      '&>button:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #D0D5DD',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '12px 49px',
        textAlign: 'center',
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '16px',
          fontWeight: 700,
          color: '#4A4A4A',
        },
      },
      '&>button:nth-of-type(2)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#0067FF',
        padding: '12px 49px',
        textAlign: 'center',
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '16px',
          fontWeight: 700,
          color: '#FFFFFF',
        },
      },
    },
  },
})

const CustomEndDateCell = (props: any) => {
  const {params} = props
  const [showValue, setShowValue] = useState(true)

  const handleClick = () => {
    setShowValue(!showValue)
  }

  return (
    <div style={{cursor: 'pointer'}} onClick={handleClick}>
      {showValue ? (
        <p style={{}}>{params.endDate}</p>
      ) : (
        <p>{params.expirationDate}</p>
      )}
    </div>
  )
}

interface SiteItemProps {
  data: any
}

const SiteItem = ({data}: SiteItemProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const listPlan = useAppSelector(planSliceFunc.selectListData)
  const [paymentMethod, setPaymentMethod] = useState('1')
  const [paymentSelect, setPaymentSelect] = useState<PlanType>()
  const [idWebsite, setIdWebsite] = useState<string>('')
  const [openModalRenew, setOpenModalRenew] = useState(false)
  const userProfile = useAppSelector(selectUserData)
  const point = userProfile?.wallet?.balance || 0
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChangePaymentSelect = (event: SelectChangeEvent, id: string) => {
    const plan: PlanType = event.target.value as unknown as PlanType
    if (window.confirm('결제를 진행하시겠습니까?')) {
      setIdWebsite(id)
      setPaymentSelect(plan)
      setOpenModalRenew(true)
    }
  }

  const handleCloseModalRenew = () => {
    setOpenModalRenew(false)
  }
  const handleClickModalRenew = () => {
    const dataPut = {
      plan: paymentSelect?._id,
    }
    axiosClient
      .put(`${SITE}/renew/${idWebsite}`, dataPut)
      .then((res: any) => {
        console.log('res put renew: ', res)
        dispatch(userActions.getUser({params: undefined}))
        axiosClient
          .get(`${SITE}/get/${idWebsite}`)
          .then((resp: {data: SiteType}) => {
            dispatch(siteActions.updateSite({updatedData: resp.data}))
          })
          .catch((error: any) => {
            console.log('error get detail site', error)
          })
        dispatch(
          snackBarActions.setStateSnackBar({
            content: '성공',
            type: 'success',
          })
        )
        setOpenModalRenew(false)
      })
      .catch((error: any) => {
        console.log(error)
        if (error.response.data.message === 'insufficient balance') {
          // handleOpenModalNotEnoughPoint()
        } else {
          dispatch(
            snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            })
          )
        }
        setOpenModalRenew(false)
      })
  }
  return (
    <div className={classes.site_item_container}>
      <div>
        <div>
          <Checkbox />
          <div className='text-[12px]'>
            {data.address.split('\n').map((line: string, i: number) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </div>
      <div style={{borderTop: '1px solid #D0D5DD', margin: '0 10px'}}></div>
      <div>
        <p className='flex'>
          <span>만료일</span> <CustomEndDateCell params={data} />
        </p>
        <p>
          <span>개설일</span> {moment(data.startDate).format('YYYY.MM.DD')}
        </p>
        <p>
          <span>관리자</span> {data.director}
        </p>
      </div>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.style_menu_item}
          key={1}
          onClick={handleClose}
        >
          관리
        </MenuItem>
        <MenuItem
          className={classes.style_menu_item}
          key={1}
          style={
            (data.webInfo?.status || '') === STATUS_SITE_UPDATE.CREATED
              ? {backgroundColor: '#3B71FE'}
              : {}
          }
          onClick={() => {
            navigate(`${ROUTE.INFOWEBSITE}/${data.id}`)
          }}
        >
          정보
        </MenuItem>
        <MenuItem
          className={classes.style_menu_item}
          key={1}
          // onClick={handleClose}
        >
          <Select
            value=''
            onChange={(event) => handleChangePaymentSelect(event, data.id)}
            displayEmpty
            sx={{}}
          >
            <MenuItem value='' style={{display: 'none'}}>
              결제
            </MenuItem>
            {(listPlan || []).map((item: any) => {
              return (
                <MenuItem value={item}>
                  {item.duration}{' '}
                  {item.typeDuration === 'month' ? '개월 결제' : '년 결제'}
                </MenuItem>
              )
            })}
          </Select>
        </MenuItem>
        <MenuItem
          className={classes.style_menu_item}
          key={1}
          onClick={() => {
            navigate(`${ROUTE.REGISTERANDMODIFYGOOGLEPLAY}/${data.id}`)
            handleClose()
          }}
          style={
            (data.androidInfo?.status || '') === STATUS_SITE_UPDATE.CREATED
              ? {backgroundColor: '#3B71FE'}
              : {}
          }
        >
          <img style={{width: '20px', height: '20px'}} src={chplay} alt='' />{' '}
          신청
        </MenuItem>
        <MenuItem
          key={1}
          style={
            (data.iosInfo?.status || '') === STATUS_SITE_UPDATE.CREATED
              ? {backgroundColor: '#3B71FE'}
              : {}
          }
          onClick={() => {
            navigate(`${ROUTE.REGISTERANDMODIFYAPPLESTORE}/${data.id}`)
            handleClose()
          }}
        >
          <img style={{width: '20px', height: '20px'}} src={appleIcon} alt='' />{' '}
          신청
        </MenuItem>
      </Menu>

      <Modal
        open={openModalRenew}
        onClose={handleCloseModalRenew}
        disableAutoFocus
      >
        <div className={classes.modalRenew}>
          <div>
            <p></p>
            <img src={closeIcon} alt='close' onClick={handleCloseModalRenew} />
          </div>
          <div>
            <img src={Social} alt='' />
            <div>
              <p>
                현재 MY 예치금:{' '}
                <span style={{color: '#0067FF'}}>
                  {numberWithCommas(point || 0)}
                </span>
                원
              </p>
              <p>
                에서 예치금{' '}
                <span style={{color: '#FF3D00'}}>
                  {numberWithCommas(paymentSelect?.price || 0)}
                </span>
                원 차감
              </p>
              <p>
                {paymentSelect?.duration}
                {paymentSelect?.typeDuration === 'month' ? '개월' : '년'} 연장을
                하기겠습니까?
              </p>
            </div>
          </div>
          <div>
            <button onClick={handleCloseModalRenew}>
              <p>취소</p>
            </button>
            <button onClick={handleClickModalRenew}>
              <p>승락</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SiteItem
